import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { Footer } from "./Footer"

// Using Client-Side Only Packages
import loadable from "@loadable/component"
const Header = loadable(() => import("./Header"))

export const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <Helmet>
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js.hs-scripts.com/6202075.js"
        ></script>
      </Helmet>
      <Header />
      {children}
      <Footer />
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
