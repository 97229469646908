import { useStaticQuery, graphql } from "gatsby"

export const useStaticImages = () => {
  const {
    siteBrandImage,
    mobileBlogBannerImage,
    mobileCareerBannerImage,
    mobileContactBannerImage,
    footerBackgroundImage,
  } = useStaticQuery(
    graphql`
      query {
        siteBrandImage: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 320) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        mobileBlogBannerImage: file(
          relativePath: { eq: "blog/blogs-mobile.svg" }
        ) {
          publicURL
        }

        mobileCareerBannerImage: file(
          relativePath: { eq: "careers/careers.png" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 720) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        mobileContactBannerImage: file(
          relativePath: { eq: "contact/contact-header.svg" }
        ) {
          publicURL
        }

        footerBackgroundImage: file(
          relativePath: { eq: "footer/footer-bg.svg" }
        ) {
          publicURL
        }
      }
    `
  )

  return {
    siteBrandImage,
    mobileBlogBannerImage,
    mobileCareerBannerImage,
    mobileContactBannerImage,
    footerBackgroundImage,
  }
}
